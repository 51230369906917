import { createHash } from 'sha1-uint8array'
import QueryString from 'qs';
import { axiosInstance } from './axios';
import { encode } from 'js-base64';
import { API_URL } from '../constants';

/// callApi - call ugcore server api
export async function callAPI(path: string, req: any, session: string = ""): Promise<any> {
    let str = JSON.stringify(req);
    let form = { "data": encode(str), "sessionid": session }
    let res = await axiosInstance.post(`${API_URL}/api/${path}`, QueryString.stringify(form), { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
    return res.data;
}

/// sha1 - generate sha1 string
export  function sha1(data: string): string {
    return createHash().update(data).digest("hex")
}
