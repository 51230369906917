import type { AuthBindings } from "@refinedev/core";
import { AuthActionResponse, CheckResponse, IdentityResponse } from "@refinedev/core/dist/interfaces";
import * as cookie from "cookie";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "./constants";
import { callAPI, sha1 } from "./utils/utils";

// Custom auth using rest api
class MyAuth {
  // login user info
  private _user?: any;
  // current session
  private _session?: string;

  async login({ email, password }: { email: string, password: string }): Promise<AuthActionResponse> {
    try {
      let res = await callAPI("auth/login", {
        "loginType": "UserPassword",
        "username": email,
        "password": sha1(password),
        "platform": "adminjs",
        "version": "1",
        "newSession": true,
      })
      if (res.status == 1) {
        let userID = res.userID;
        let sessionID = res.sessionID;
        res = await callAPI("auth/user", { "userID": userID }, sessionID)
        if (res.status == 1) {
          this._user = res.user;
          this._session = sessionID;
          Cookies.set(TOKEN_KEY, JSON.stringify({
            user: res.user,
            session: sessionID,
          }));
          return { success: true, redirectTo: "/" };
        }
      }
    } catch (e) {
      return { success: false, error: new Error(`${e}`) };
    }
    return { success: false };
  }

  async logout(): Promise<AuthActionResponse> {
    this._user = null;
    Cookies.remove(TOKEN_KEY);
    return { success: true, redirectTo: "/login" };
  }

  async checkAuth(request: any): Promise<CheckResponse> {
    let token = undefined;
    let pathname = "";
    if (request && request.headers) {
      const parsedCookie = cookie.parse(request.headers.get("Cookie") ?? "");
      token = parsedCookie[TOKEN_KEY];
      pathname = new URL(request.url).pathname;
    }
    if (token == null) {
      const parsedCookie = Cookies.get(TOKEN_KEY);
      token = parsedCookie;
    }

    if (token) {
      let parsed = JSON.parse(token)
      this._session = parsed.session;
      this._user = parsed.user;
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: new Error("Unauthenticated"),
      logout: true,
      redirectTo: `/login?to=${pathname}`,
    };
  }

  async getPermissions() {
    return Promise.resolve();
  }

  async getUserIdentity(context: any = null) {
    await this.checkAuth(context)
    return {
      id: this._user?.id,
      name: this._user?.name,
      avatar: this._user?.thumbnail,
      session: this._session,
    };
  }
}

const auth = new MyAuth()

export const authProvider = {
  login: (params: { email: string, password: string }) => auth.login(params),
  logout: () => auth.logout(),
  onError: async (error: any) => {
    console.error(error);
    return { error };
  },
  check: (request: any) => auth.checkAuth(request),
  getPermissions: () => auth.getPermissions(),
  getIdentity: (context?: any) => auth.getUserIdentity(context),
};
